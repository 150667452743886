import React from "react";
import s from "./SilencingPage.module.css";
import work from "../../../assets/test.png"

const SilencingPage = () => {
return(
    <div className={s.mainContainer}>
        <div className={s.silencingPage}>
        <h4>Похоже идет разработка... </h4>
        </div>
        
    </div>
)
}

export default SilencingPage;